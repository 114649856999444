<template>
  <app-overlay loading-variable="modalOverlay">
    <db-searchable-table v-model="filters" @changed="getFiles" class="border" :items="items" :total-rows="total" :fields="fields" searchable>
      <template #actions="{ item }">
        <app-dropdown>
          <b-dropdown-item @click="downloadFile(item._id)"> <feather-icon icon="DownloadIcon" class="mr-50" /> İndir</b-dropdown-item>
        </app-dropdown>
      </template>
    </db-searchable-table>
  </app-overlay>
</template>

<script>
export default {
  data() {
    return {
      filters: {},
      fields: [
        { key: "customName", label: "Dosya Adı" },
        { key: "fileGroup.value", label: "Dosya Türü" },
        { key: "createdAt", label: "Yüklenme Tarihi", formatDateTime: true },
        { key: "actions", label: "Eylemler" },
      ],
      items: [],
      total: 0,
    };
  },
  computed: {
    user() {
      return this.$store.getters?.user?.riskomerEmployee;
    },
  },
  mounted() {
    this.getFiles();
  },
  methods: {
    getFiles() {
      this.$axios
        .get("/riskomer-employee-files", { params: { ...this.filters, riskomerEmployee: this.user._id }, loading: "modal" })
        .then((res) => {
          this.items = res.data.data;
          this.total = res.data.total;
        })
        .catch((err) => {
          console.log(err);
        });
    },
    downloadFile(id) {
      this.$axios
        .get(`/riskomer-employee-files/${id}`, { loading: "modal", responseType: "blob" })
        .then((res) => {
          const blob = new Blob([res.data], { type: res.headers["content-type"] });
          const url = window.URL.createObjectURL(blob);
          window.open(url, "_blank");
        })
        .catch((err) => {
          this.$emitter.$emit("Notification", {
            variant: "danger",
            title: "Hata Oluştu",
            message: err?.data?.message ?? null,
          });
        });
    },
  },
};
</script>

<style></style>
