<template>
  <b-custom-tabs :tabs="tabs" lazy-once />
</template>

<script>
import filesVue from './files/index.vue'
import profile from './profile/index.vue'

export default {
  data() {
    return {
      tabs: [
        { title: 'Profil Bilgileri', component: profile },
        { title: 'Dosyalar', component: filesVue },
      ],
    }
  },
}
</script>

<style></style>
