<template>
  <validation-observer ref="profileInfoForm">
    <b-card title="Genel Bilgiler">
      <b-row v-if="user">
        <b-col md="6">
          <app-input v-model="user.fullname" name="Ad Soyad" label="Ad Soyad:" placeholder="Ad Soyad..." rules="required" />
        </b-col>
        <b-col md="6">
          <app-input v-model="user.email" name="E-Posta" label="E-Posta:" rules="required|email" />
        </b-col>
        <b-col md="6">
          <app-input v-model="user.phone" name="Telefon" label="Telefon:" rules="required|length:11" />
        </b-col>
        <b-col md="6">
          <app-input v-model="user.physician.sertificateNumber" name="Sertifika Numarası" label="Sertifika Numarası:" rules="required" />
        </b-col>
        <b-col md="6">
          <app-input v-model="user.physician.workType" name="Çalışma Şekli" label="Çalışma Şekli:" disabled />
        </b-col>
        <b-col md="6">
          <app-input v-model="user.physician.assignmentTime" type="number" name="Atama Dakikası" label="Atama Dakikası:" disabled />
        </b-col>
      </b-row>
      <hr />
      <div>
        <app-button @click="showSmsModal" text="Güncelle" size="md" />
      </div>
    </b-card>
  </validation-observer>
</template>

<script>
import { ValidationObserver } from "vee-validate";
import SmsCheckModal from "./SmsCheckModal.vue";
import { getUserData } from "@/auth/utils";
export default {
  components: { ValidationObserver },
  data() {
    return {
      user: null,
    };
  },
  methods: {
    showSmsModal() {
      this.$validate(this.$refs.profileInfoForm, () => {
        this.$axios
          .post("/sms-confirm/send-sms", {}, { loading: "table" })
          .then(() => {
            this.$showAppModal({
              title: "SMS Onayı",
              component: SmsCheckModal,
              size: "sm",
              item: {
                callback: this.updatePhysician,
              },
            });
          })
          .catch((err) => {
            this.$emitter.$emit("Notification", {
              variant: "danger",
              title: "Hata Oluştu",
              message: err?.data?.message ?? null,
            });
          });
      });
    },
    updatePhysician(token) {
      this.$axios
        .patch(
          "/physicians/update-physician",
          {
            auth: {
              fullname: this.user?.fullname,
              email: this?.user?.email,
              phone: this?.user?.phone.replaceAll(" ", ""),
            },
            data: {
              sertificateNumber: this?.user?.physician?.sertificateNumber,
            },
          },
          { headers: { Authorization: token }, loading: "table" }
        )
        .then(() => {
          this.$store.commit("setUser", { ...this.user });
          this.$emitter.$emit("Notification", {
            variant: "success",
            title: "İşlem Başarılı",
          });
        })
        .catch((err) => {
          this.$emitter.$emit("Notification", {
            variant: "danger",
            title: "Hata Oluştu",
            message: err?.data?.message ?? null,
          });
        });
    },
  },
  mounted() {
    const userData = getUserData();
    this.$nextTick(() => {
      this.user = { ...userData };
    });
  },
};
</script>

<style></style>
