<template>
  <app-overlay>
    <b-row>
      <b-col md="8">
        <profile-info />
      </b-col>
      <b-col md="4">
        <password-change />
      </b-col>
    </b-row>
  </app-overlay>
</template>

<script>
import profileInfo from "./profileInfo.vue";
import passwordChange from "./passwordChange.vue";

export default {
  components: { passwordChange, profileInfo },
};
</script>

<style></style>
